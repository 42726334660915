.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  max-height: 100vh;

  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 1px;
  
}
.profile-photo{
  border-radius: 50%;
  width: 15vw;
  
}
.contact-details-text{
  font-size: medium;
}
.details-section{
  background-color: aliceblue;
  overflow-y: scroll;
  max-height:80vh;
  color: #282c34;
}
.details-section-text{
  font-size: medium;
}
.details-section-header-1{
  font-size: large;
}
.details-section-header-2{
  font-size: medium;
}
.details-section-header-3{
  font-size: small;
}.details-section-header-4{
  font-size: smaller;
}
.summary-col{
  background-color: #282c34;
  min-height: 80vh;
  color :#ffff;
}
.App-link {
  color: #61dafb;
}
.header{
  max-height: 20vh;
}
 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

